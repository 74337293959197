import { AUTH_EDIT, REMOVE_TOKEN, SET_AUTH_DATA, VERIFY_EMAIL } from "../types/auth";

const initState = {
  token: "",
  user: {},
};

const reducer = (state = initState, action: { type: string; data: any }) => {
  switch (action.type) {
    case AUTH_EDIT:
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    case SET_AUTH_DATA:
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        user: { ...state.user, emailVerified: true },
      };
    case REMOVE_TOKEN:
      return initState;
    default:
      return state;
  }
};

export default reducer;
