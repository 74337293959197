import "./sidebar.css";
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@material-ui/icons";
import { Link, Outlet, useParams, useLocation } from "react-router-dom";

export default function Sidebar(props) {
  const { pathname } = useLocation();
  const { companyId } = useParams();

  const handleActiveTab = (to) => {
    const isActive = to === pathname;
    return isActive ? "active" : "";
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="sidebar">
        <div className="sidebarWrapper">
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Dashboard</h3>
            <ul className="sidebarList">
              <Link to="/" className="link">
                <li className="sidebarListItem">
                  <LineStyle className="sidebarIcon" />
                  Home
                </li>
              </Link>
              <Link to={`/company/${companyId}`} className="link">
                <li className={`sidebarListItem ${handleActiveTab(`/company/${companyId}`)}`}>
                  <Timeline className="sidebarIcon" />
                  Analytics
                </li>
              </Link>
              {/* <li className="sidebarListItem">
                <TrendingUp className="sidebarIcon" />
                Sales
              </li> */}
            </ul>
          </div>
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Quick Menu</h3>
            <ul className="sidebarList">
              {/* <Link to="/users" className="link">
                <li className="sidebarListItem">
                  <PermIdentity className="sidebarIcon" />
                  Users
                </li>
              </Link> */}
              <Link to={`/company/${companyId}/products`} className="link">
                <li className={`sidebarListItem ${handleActiveTab(`/company/${companyId}/products`)}`}>
                  <Storefront className="sidebarIcon" />
                  Products
                </li>
              </Link>
              {/* <li className="sidebarListItem">
                <AttachMoney className="sidebarIcon" />
                Transactions
              </li>
              <li className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Reports
              </li> */}
            </ul>
          </div>
          {/* <div className="sidebarMenu">
            <h3 className="sidebarTitle">Notifications</h3>
            <ul className="sidebarList">
              <li className="sidebarListItem">
                <MailOutline className="sidebarIcon" />
                Mail
              </li>
              <li className="sidebarListItem">
                <DynamicFeed className="sidebarIcon" />
                Feedback
              </li>
              <li className="sidebarListItem">
                <ChatBubbleOutline className="sidebarIcon" />
                Messages
              </li>
            </ul>
          </div> */}
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Staff</h3>
            <ul className="sidebarList">
              <li className="sidebarListItem">
                <WorkOutline className="sidebarIcon" />
                Manage
              </li>
              {/* <li className="sidebarListItem">
                <Timeline className="sidebarIcon" />
                Analytics
              </li> */}
              <li className="sidebarListItem">
                <Report className="sidebarIcon" />
                Reports
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
    </div>
  );
}
