import { SET_COMPANIES } from "../types/company";

const initState = {
  companies: [],
};

const reducer = (state = initState, action: { type: string; data: any }) => {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
