import { SET_ALL_PRODUCTS, SET_PRODUCT } from "../types/products";

const initState = {
  products: [],
};

const reducer = (state = initState, action: { type: string; data: any }) => {
  switch (action.type) {
    case SET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.data.docs,
      };
    case SET_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.data],
      };
    default:
      return state;
  }
};

export default reducer;
