import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Sidebar from "../../common/components/sidebar";

const NavBar = React.lazy(() => import("../../common/components/navbar"));
const RequireAuth = React.lazy(() => import("../auth-manager/RequireAuth"));
const IsUserVerified = React.lazy(() => import("../auth-manager/IsUserVerified"));
const IsAuthExist = React.lazy(() => import("./IsAuthExist"));

// PAGES
const LoginPage = React.lazy(() => import("../../Pages/login"));
const DashboardPage = React.lazy(() => import("../../Pages/dashboard"));
const RegisterPage = React.lazy(() => import("../../Pages/register"));
const PageNotFoundPage = React.lazy(() => import("../../Pages/error"));
const PricingPage = React.lazy(() => import("../../Pages/pricing"));
const ForgotPasswordPage = React.lazy(() => import("../../Pages/forgot-password"));
const ResetPasswordPage = React.lazy(() => import("../../Pages/reset-password"));
const EmailVerifyPage = React.lazy(() => import("../../Pages/email-verify"));
const ProfilePage = React.lazy(() => import("../../Pages/profile"));
const UpdateProfilePage = React.lazy(() => import("../../Pages/update-profile"));
const CompaniesListPage = React.lazy(() => import("../../Pages/companies-list"));
const ProductsList: any = React.lazy(() => import("../../Pages/products-list"));

function RootRouter() {
  return (
    <Routes>
      <Route element={<IsAuthExist />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/Register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Route>

      <Route element={<NavBar />}>
        <Route element={<RequireAuth />}>
          <Route path="/" element={<CompaniesListPage />} />

          <Route path="company" element={<Sidebar />}>
            <Route path=":companyId" element={<Outlet />}>
              <Route path="" element={<DashboardPage />} />

              <Route path="products" element={<Outlet />}>
                <Route path="" element={<ProductsList />} />
              </Route>
            </Route>
          </Route>

          <Route path="user" element={<Outlet />}>
            <Route path="profile" element={<ProfilePage />} />
            <Route path="profile-update" element={<UpdateProfilePage />} />
          </Route>
        </Route>

        <Route path="/Pricing" element={<PricingPage />} />
        <Route path="/verify-email" element={<EmailVerifyPage />} />
      </Route>
      <Route path="*" element={<PageNotFoundPage />} />
    </Routes>
  );
}

export default RootRouter;
