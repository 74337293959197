import { ADD_LINKS, SET_LINKS } from "../types/links";

const initState = {
  links: [],
};

const reducer = (state = initState, action: { type: string; data: any }) => {
  switch (action.type) {
    case SET_LINKS:
      return {
        ...state,
        ...action.data,
      };

    case ADD_LINKS:
      return {
        ...state,
        links: [...state.links, action.data],
      };
    default:
      return state;
  }
};

export default reducer;
