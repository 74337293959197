import { combineReducers } from "redux";

import AuthReducer from "./auth";
import LoaderReducer from "./loader";
import ProfileReducer from "./profile";
import CompanyReducer from "./company";
import LinksReducer from "./links";
import ProductsReducer from "./products";

export default combineReducers({
  auth: AuthReducer,
  loader: LoaderReducer,
  profile: ProfileReducer,
  company: CompanyReducer,
  link: LinksReducer,
  products: ProductsReducer,
});
