export const DEFAULT_TOASTER: any = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

export const gridSpacing = 3;
export const drawerWidth = 200;
export const appDrawerWidth = 320;
