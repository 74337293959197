// import

import { PROFILE_EDIT } from "../types/profile";

const initState = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  company: "",
};

const reducer = (state = initState, action: { type: string; data: any }) => {
  switch (action.type) {
    case PROFILE_EDIT:
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    default:
      return state;
  }
};

export default reducer;
